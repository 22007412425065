import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { HTTP_MESSAGES } from 'app/shared/constants';
import { IDiscount } from 'app/shared/interfaces';
import { catchError, tap } from 'rxjs/operators';
import { AlertService, DiscountsService, ErrorHandlerService } from '../services';
import * as actions from './action-namespaces/discounts';

export interface DiscountsState {
  loading: boolean;
  discounts: IDiscount[];
  searchResultVendor: string;
  searchResultManufacturer: string;
}

@State<DiscountsState>({
  name: 'discounts',
  defaults: {
    loading: false,
    discounts: [],
    searchResultVendor: null,
    searchResultManufacturer: null,
  }
})
@Injectable()
export class DiscountsStore {
  constructor(
    private readonly discountsService: DiscountsService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) { }

  @Action(actions.Discount.GetAll)
  getDiscounts(ctx: StateContext<DiscountsState>) {
    ctx.patchState({ discounts: [], loading: true });
    return this.discountsService.getDiscounts().pipe(
      tap(discounts => ctx.patchState({ discounts, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Discount.Create)
  createDiscount(ctx: StateContext<DiscountsState>, action: actions.Discount.Create) {
    return this.discountsService.createDiscount(action.payload).pipe(
      tap(discount => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.setState(patch({ discounts: append<IDiscount>([discount]) }));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Discount.Update)
  updateDiscount(ctx: StateContext<DiscountsState>, action: actions.Discount.Update) {
    return this.discountsService.updateDiscount(action.payload).pipe(
      tap(discount => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.setState(patch({ discounts: updateItem<IDiscount>(d => d.id === discount.id, discount) }));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Discount.Delete)
  deleteDiscount(ctx: StateContext<DiscountsState>, action: actions.Discount.Delete) {
    return this.discountsService.deleteDiscount(action.payload).pipe(
      tap(id => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
        return ctx.setState(patch({ discounts: removeItem<IDiscount>(d => d.id == id) }));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.GeneralPurpose.ResetDiscountsField)
  resetField(ctx: StateContext<DiscountsState>, action: actions.GeneralPurpose.ResetDiscountsField) {
    const { name, value } = action.payload;
    ctx.patchState({ [name]: value });
  };

  @Action(actions.GeneralPurpose.FindVendor)
  findVendor(ctx: StateContext<DiscountsState>, action: actions.GeneralPurpose.FindVendor) {
    return this.discountsService.findVendor(action.payload).pipe(
      tap(searchResultVendor => ctx.patchState({ searchResultVendor })),
      catchError(e => {
        ctx.patchState({ searchResultVendor: '' });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  };

  @Action(actions.GeneralPurpose.FindManufacturer)
  findManufacturer(ctx: StateContext<DiscountsState>, action: actions.GeneralPurpose.FindManufacturer) {
    return this.discountsService.findManufacturer(action.payload).pipe(
      tap(searchResultManufacturer => ctx.patchState({ searchResultManufacturer })),
      catchError(e => {
        ctx.patchState({ searchResultManufacturer: '' });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  };

}
