import { Component, Input, OnInit } from '@angular/core';
import { AppDataService } from "../../_services/app_data.service";

@Component({
  selector: 'invoice-vats',
  templateUrl: './vat.html',
  styleUrls: ['./vat.css']
})
export class InvoiceVatsComponent implements OnInit{
  constructor(
    public appDataService: AppDataService
  ) { }
  ngOnInit() { }
}
