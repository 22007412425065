import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AlertService, ErrorHandlerService, GatewaysService } from '../services';
import * as actions from './action-namespaces/gateways';
import { catchError, tap } from 'rxjs/operators';
import { HTTP_MESSAGES } from 'app/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { IGateway, IGatewayResponse } from 'app/shared/interfaces';

export interface GatewaysState {
  loading: boolean;
  gateways: IGateway[];
  unpublishedCount: number;
  myUnpublishedCount: number;
  searchResult: string;
}

@State<GatewaysState>({
  name: 'gateways',
  defaults: {
    loading: false,
    gateways: [],
    myUnpublishedCount: null,
    unpublishedCount: null,
    searchResult: null,
  }
})
@Injectable()
export class GatewaysStore {
  constructor(
    private readonly gatewaysService: GatewaysService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) { }

  @Action(actions.Gateway.GetAll)
  getGateways(ctx: StateContext<GatewaysState>) {
    ctx.patchState({
      gateways: [],
      unpublishedCount: null,
      myUnpublishedCount: null,
      loading: true,
    });
    return this.gatewaysService.getGateways().pipe(
      tap(response => ctx.patchState(
        {
          gateways: response.gateways.map(g => ({
            ...g,
            _id: g.gateway_code,
            status_date: g.status_date.slice(0, 10),
          })),
          unpublishedCount: response.unpublished_count,
          myUnpublishedCount: response.my_unpublished_count,
          loading: false,
        }
      )),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Gateway.Create)
  createGateway(ctx: StateContext<GatewaysState>, action: actions.Gateway.Create) {
    return this.gatewaysService.createGateway(action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Gateway.GetAll);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Gateway.Update)
  updateGateway(ctx: StateContext<GatewaysState>, action: actions.Gateway.Update) {
    return this.gatewaysService.updateGateway(action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Gateway.GetAll);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Gateway.Publish)
  publishGateways(ctx: StateContext<GatewaysState>, action: actions.Gateway.Publish) {
    const { type, id, refetchGateways } = action.payload;
    return this.gatewaysService.publishGateways(type, id).pipe(
      tap(message => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message, type: message === 'Success' ? 'SUCCESS' : 'ERROR' });
        if (refetchGateways) ctx.dispatch(new actions.Gateway.GetAll);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.GeneralPurpose.ResetGatewaysField)
  resetField(ctx: StateContext<GatewaysState>, action: actions.GeneralPurpose.ResetGatewaysField) {
    const { name, value } = action.payload;
    ctx.patchState({ [name]: value });
  };

  @Action(actions.GeneralPurpose.FindName)
  findName(ctx: StateContext<GatewaysState>, action: actions.GeneralPurpose.FindName) {
    return this.gatewaysService.findName(action.payload).pipe(
      tap(searchResult => ctx.patchState({ searchResult })),
      catchError(e => {
        ctx.patchState({ searchResult: '' });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  };

}