import { IDashboardData, IInventoryOrder, IInvtOrder, IReportOrder, IOrderItem, ReportFilterType } from 'app/shared/interfaces';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AlertService, ErrorHandlerService, ProcurementService } from '../services';
import * as actions from './action-namespaces/procurement';
import { catchError, tap } from 'rxjs/operators';
import { HTTP_MESSAGES } from 'app/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { InventoryTypes } from 'app/shared/enums';

export interface ProcurementState {
  loading: boolean;
  reportOrders: IReportOrder[];
  reportFilter: ReportFilterType;
  dashboardData: IDashboardData;
  inventoryOrders: IInventoryOrder[];
  additionalDocuments: IInventoryOrder[];
  additionalDocumentsLoading: boolean;
  invtOrders: IInvtOrder[];
  invtOrdersLoading: boolean;
}

@State<ProcurementState>({
  name: 'procurement',
  defaults: {
    loading: false,
    reportOrders: [],
    reportFilter: 'all',
    dashboardData: null,
    inventoryOrders: [],
    additionalDocuments: [],
    additionalDocumentsLoading: false,
    invtOrders: [],
    invtOrdersLoading: false,
  }
})
@Injectable()
export class ProcurementStore {
  constructor(
    private readonly procurementService: ProcurementService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) { }

  @Action(actions.ReportOrders.GetAll)
  getReportOrders(ctx: StateContext<ProcurementState>) {
    ctx.patchState({ reportOrders: [], loading: true });
    return this.procurementService.getReportOrders().pipe(
      tap(orders => ctx.patchState({ reportOrders: this.prepareOrders(orders), loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  prepareOrders(orders: IReportOrder[]): IReportOrder[] {
    const preparedOrders: IReportOrder[] = orders.map((o, indx) => ({
      ...o,
      _id: ++indx,
      items: o.items.map(i => ({
        ...i,
        vfm: i.vfm === 'EAGN' ? 'PSSC' : i.vfm,
      })),
    }));
    const ids: number[] = [];
    preparedOrders.forEach(o => {
      if (!ids.includes(o.ord_id)) ids.push(o.ord_id);
    });
    return ids.map(id => ({
      ...preparedOrders.find(o => o.ord_id === id),
      items: this.getItems(preparedOrders, id),
    }));
  };

  getItems(orders: IReportOrder[], id: number): IOrderItem[] {
    const items: IOrderItem[] = [];
    orders.forEach(o => {
      if (o.ord_id === id) items.push(...o.items);
    });
    return items;
  };

  @Action(actions.ReportOrders.SetReportFilter)
  setReportFilter(ctx: StateContext<ProcurementState>, action: actions.ReportOrders.SetReportFilter) {
    ctx.patchState({ reportFilter: action.payload });
  };

  @Action(actions.GeneralPurpose.GetDashboardData)
  getDashboardData(ctx: StateContext<ProcurementState>) {
    return this.procurementService.getDashboardData().pipe(
      tap(dashboardData => ctx.patchState({ dashboardData })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.InventoryOrders.GetAll)
  getInventoryOrders(ctx: StateContext<ProcurementState>, action: actions.InventoryOrders.GetAll) {
    ctx.patchState({ inventoryOrders: [], loading: true });
    return this.procurementService.getInventoryOrders(action.payload).pipe(
      tap(inventoryOrders => ctx.patchState({ inventoryOrders, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.InventoryOrders.Edit)
  editInventoryOrder(ctx: StateContext<ProcurementState>, action: actions.InventoryOrders.Edit) {
    return this.procurementService.editInventoryOrder(action.payload.id, action.payload.data).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        const actionsToDispatch = [new actions.GeneralPurpose.GetDashboardData(), new actions.InventoryOrders.GetAll(action.payload.tab)];
        if (action.payload.tab === InventoryTypes.Placed) {
          actionsToDispatch.push(new actions.InventoryOrders.GetAdditionalDocuments());
        }
        return ctx.dispatch(actionsToDispatch);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.InventoryOrders.BulkEdit)
  bulkEditInventoryOrders(ctx: StateContext<ProcurementState>, action: actions.InventoryOrders.BulkEdit) {
    return this.procurementService.bulkEditInventoryOrders(action.payload.ids, action.payload.data).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        const actionsToDispatch = [new actions.GeneralPurpose.GetDashboardData(), new actions.InventoryOrders.GetAll(action.payload.tab)];
        if (action.payload.tab === InventoryTypes.Placed) {
          actionsToDispatch.push(new actions.InventoryOrders.GetAdditionalDocuments());
        }
        return ctx.dispatch(actionsToDispatch);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.InventoryOrders.GetAdditionalDocuments)
  getAdditionalDocuments(ctx: StateContext<ProcurementState>) {
    ctx.patchState({ additionalDocuments: [], additionalDocumentsLoading: true });
    return this.procurementService.getAdditionalDocuments().pipe(
      tap(additionalDocuments => ctx.patchState({ additionalDocuments, additionalDocumentsLoading: false })),
      catchError(e => {
        ctx.patchState({ additionalDocumentsLoading: false });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e);
      }),
    );
  };

  @Action(actions.InventoryOrders.EditAdditionalDocument)
  editAdditionalDocument(ctx: StateContext<ProcurementState>, action: actions.InventoryOrders.EditAdditionalDocument) {
    return this.procurementService.editAdditionalDocument(action.payload.id, action.payload.data).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.InventoryOrders.GetAdditionalDocuments());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.InvtOrders.GetAll)
  getInvtOrders(ctx: StateContext<ProcurementState>, action: actions.InvtOrders.GetAll) {
    ctx.patchState({ invtOrders: [], invtOrdersLoading: true });
    return this.procurementService.getInvtOrders(action.payload).pipe(
      tap(invtOrders => ctx.patchState({ invtOrders, invtOrdersLoading: false })),
      catchError(e => {
        ctx.patchState({ invtOrdersLoading: false });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e);
      }),
    );
  };

  @Action(actions.InvtOrders.GetItems)
  getInvtOrderItems(ctx: StateContext<ProcurementState>, action: actions.InvtOrders.GetItems) {
    return this.procurementService.getInvtOrderItems(action.payload).pipe(
      tap(items => ctx.patchState({
        invtOrders: ctx.getState().invtOrders.map(order => order.id === action.payload ? { ...order, items } : order),
      })),
      catchError(e => {
        ctx.patchState({ invtOrdersLoading: false });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e);
      }),
    );
  };

}
