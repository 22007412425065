import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-place-order',
  templateUrl: './confirm-place-order.component.html',
  styleUrls: ['./confirm-place-order.component.scss']
})
export class ConfirmPlaceOrderComponent {

  @Input() dialogRef: MatDialogRef<any>;

  confirmSaveOrder() {
    this.dialogRef.close(true);
  }

}
