import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sa-invoice-remit-info',
  templateUrl: './sa.html'
})
export class SaInvoiceRemitComponent implements OnInit{
  constructor() { }
  ngOnInit() { }
}
