import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: "sortBy" })
export class SortPipe implements PipeTransform {
  transform(array: Array<string>, args: string,orderType:string): Array<string> {
    if (array != undefined) {
      if(orderType === 'DESC'){
        array.sort((a: any, b: any) => {
          if (a[args] > b[args]) {
            return -1;
          } else if (a[args] < b[args]) {
            return 1;
          } else {
            return 0;
          }
        });

      }else{
        array.sort((a: any, b: any) => {
          if (a[args] < b[args]) {
            return -1;
          } else if (a[args] > b[args]) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return array;
    }
  }
}
