import { RelationTypes, PublishTypes } from 'app/shared/enums';
import {
  IBundle,
  IProductGateway,
  IInfoUpdate,
  IOrgAndSubGroupsUpdate,
  IProductCreatePayload,
  IProductEvent,
  IProductItem,
  IProductNote,
  IRelation,
  IResetFieldPayload,
  ISubGroup,
  IEditPDFPayload,
} from 'app/shared/interfaces';
import { ProductsState } from 'app/store';

export namespace Product {
  export class GetLandingPageList {
    static readonly type = '[Products] Get Landing Page List';
  }
  
  export class Create {
    static readonly type = '[Products] Create Product';
    constructor(public payload: IProductCreatePayload) { }
  }

  export class Delete {
    static readonly type = '[Products] Delete Product';
    constructor(public payload: string) { }
  }
  
  export class GetOne {
    static readonly type = '[Products] Get Product';
    constructor(public payload: string) { }
  }

  export class Copy {
    static readonly type = '[Products] Copy Product';
  }
  
  export class UpdateOrgAndSubGroups {
    static readonly type = '[Products] Update Organization And Sub Groups';
    constructor(public payload: IOrgAndSubGroupsUpdate) { }
  }
  
  export class UpdateInfo {
    static readonly type = '[Products] Update Info';
    constructor(public payload: IInfoUpdate) { }
  }

  export class DoNotShow {
    static readonly type = '[Products] Do Not Show';
    constructor(public payload?: string) { }
  }

  export class Publish {
    static readonly type = '[Products] Publish Products';
    constructor(public payload: { type: PublishTypes; id?: string, refetchProducts?: boolean }) { }
  }
}

export namespace SubGroup {
  export class GetSubGroup {
    static readonly type = '[Products] Get Sub Group';
    constructor(public payload: string) { }
  }

  export class AddSubGroup {
    static readonly type = '[Products] Add Sub Group';
    constructor(public payload: string) { }
  }

  export class DeleteSubGroup {
    static readonly type = '[Products] Delete Sub Group';
    constructor(public payload: string) { }
  }

  export class GetSubGroups {
    static readonly type = '[Products] Get Sub Groups';
  }

  export class CreateSubGroup {
    static readonly type = '[Products] Create Sub Group';
    constructor(public payload: ISubGroup) { }
  }

  export class SearchOrganization {
    static readonly type = '[Products] Search Organization';
    constructor(public payload: string) { }
  }

  export class SearchSubGroup {
    static readonly type = '[Products] Search SubGroup';
    constructor(public payload: string) { }
  }
}

export namespace ProductNotes {
  export class GetAll {
    static readonly type = '[Products] Get Product Notes';
  }
  
  export class Update {
    static readonly type = '[Products] Update Product Note';
    constructor(public payload: IProductNote) { }
  }

  export class Create {
    static readonly type = '[Products] Create Product Note';
    constructor(public payload: IProductNote) { }
  }

  export class Delete {
    static readonly type = '[Products] Delete Product Note';
    constructor(public payload?: string) { }
  }
}

export namespace GeneralPurpose {
  export class ResetProductsField {
    static readonly type = '[Products] Reset Field';
    constructor(public payload: IResetFieldPayload<ProductsState>) { }
  }

  export class Search {
    static readonly type = '[Products] Search';
    constructor(public payload: string) { }
  }

  export class SearchByPrddId {
    static readonly type = '[Products] Search By PrddId';
    constructor(public payload: string) { }
  }
}

export namespace Bundle {
  export class GetAll {
    static readonly type = '[Products] Get Bundles';
  }
  
  export class Update {
    static readonly type = '[Products] Update Bundle';
    constructor(public payload: IBundle) { }
  }

  export class Create {
    static readonly type = '[Products] Create Bundle';
    constructor(public payload: IBundle) { }
  }

  export class Delete {
    static readonly type = '[Products] Delete Bundle';
    constructor(public payload: string) { }
  }

  export class GetFormats {
    static readonly type = '[Products] Bundle Formats';
  }
}

export namespace Event {
  export class GetAll {
    static readonly type = '[Products] Get Events';
  }
  
  export class Update {
    static readonly type = '[Products] Update Event';
    constructor(public payload: IProductEvent) { }
  }

  export class Create {
    static readonly type = '[Products] Create Event';
    constructor(public payload: IProductEvent) { }
  }

  export class Delete {
    static readonly type = '[Products] Delete Event';
    constructor(public payload: string) { }
  }
}

export namespace Relation {
  export class GetAll {
    static readonly type = '[Products] Get Relations';
  }
  
  export class Update {
    static readonly type = '[Products] Update Relation';
    constructor(public payload: IRelation) { }
  }

  export class Create {
    static readonly type = '[Products] Create Relation';
    constructor(public payload: IRelation) { }
  }

  export class Delete {
    static readonly type = '[Products] Delete Relation';
    constructor(public payload: string) { }
  }
}

export namespace Item {
  export class GetAll {
    static readonly type = '[Products] Get Items';
  }

  export class Update {
    static readonly type = '[Products] Update Item';
    constructor(public payload: Partial<IProductItem>) { }
  }

  export class Create {
    static readonly type = '[Products] Create Item';
    constructor(public payload: Partial<IProductItem>) { }
  }

  export class Delete {
    static readonly type = '[Products] Delete Item';
    constructor(public payload: string) { }
  }

  export class GetPriceCodes {
    static readonly type = '[Products] Get Price Codes';
    constructor(public payload: string) { }
  }

  export class AddPdf {
    static readonly type = '[Products] Add Pdf';
    constructor(public payload: IEditPDFPayload) { }
  }

  export class DeletePdf {
    static readonly type = '[Products] Delete Pdf';
    constructor(public payload: { itemId: string, pdfId: string }) { }
  }

  export class UpdatePdf {
    static readonly type = '[Products] Update Pdf';
    constructor(public payload: IEditPDFPayload) { }
  }
}

export namespace Gateway {
  export class GetAll {
    static readonly type = '[Products] Get Gateways';
  }
  
  export class Update {
    static readonly type = '[Products] Update Gateway';
    constructor(public payload: IProductGateway) { }
  }

  export class Create {
    static readonly type = '[Products] Create Gateway';
    constructor(public payload: IProductGateway) { }
  }

  export class Delete {
    static readonly type = '[Products] Delete Gateway';
    constructor(public payload: string) { }
  }
}

export namespace CSV {
  export class Export {
    static readonly type = '[Products] Export CSV';
    constructor(public payload: string) { }
  }

  export class Import {
    static readonly type = '[Products] Import CSV';
    constructor(public payload: File) { }
  }
}
