import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'css-invoice-header',
  templateUrl: './css.html',
  styleUrls: ['./css.css']
})
export class CssInvoiceHeaderComponent implements OnInit{
  constructor() { }
  ngOnInit() { }
}
