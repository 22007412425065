import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aud-invoice-header',
  templateUrl: './aud.html',
  styleUrls: ['./aud.css']
})
export class AudInvoiceHeaderComponent implements OnInit{
  constructor() { }
  ngOnInit() { }
}
