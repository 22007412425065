import { Mappable } from './mappable';

export class Payment extends Mappable {
  id: number;
  reference_date: Date;
  amount_paid: number;
  description?: string;
  method: string;
  person_id: number;
  cc_id?: number;
  currency: string;
  status: string;
  available_amount: number;
  account_balance: number;
  account_balance_aud: number;
  search_invoice_id:number;
  constructor() {
    super();
  }
}
