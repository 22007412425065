
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { tap, catchError } from "rxjs/operators";
import {SpinnerService} from "./spinner.service";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.start();
    return next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        this.spinnerService.stop();
      }
    }),
    catchError(response => {
        if (response instanceof HttpErrorResponse) {
          this.spinnerService.stop();
        }

        return observableThrowError(response);
      }));
  }
}
