import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from '../_services/order.service';
import { Order } from '../_models/order';
import {LineItem} from '../_models/line_item';
import {Invoice} from '../_models/invoice';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  @Input() order_id: number;
  @Input() dialogRef: MatDialogRef<any>;
  order: Order = new Order;

  constructor(private orderService: OrderService) { }

  ngOnInit() {
    this.orderService.get(this.order_id).subscribe(
      success => {
        this.order = new Order().fromJSON(success);
        this.order.invoices = this.order.invoices.map(invoice => {
          invoice = new Invoice().fromJSON(invoice);
          invoice.line_items = invoice.line_items.map((li) => new LineItem().fromJSON(li));
          return invoice;
        });
      });
  }

}
