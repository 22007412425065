import { PublishTypes } from 'app/shared/enums';
import {
  IContact,
  IMultiUserException,
  IMultiUserPricing,
  IPriceCode,
  IResetFieldPayload,
  IRestriction,
  ISubVendor,
  IVendor,
  VendorCreatePayload,
} from 'app/shared/interfaces';
import { VendorsState } from 'app/store';

export namespace GeneralPurpose {
  export class GetZipCodeDetails {
    static readonly type = '[Vendors] Get ZipCode Details';
    constructor(public payload: string) { }
  }

  export class ResetVendorsField {
    static readonly type = '[Vendors] Reset Field';
    constructor(public payload: IResetFieldPayload<VendorsState>) { }
  }

  export class FindProduct {
    static readonly type = '[Vendors] Find Product';
    constructor(public payload: string) { }
  }
}

export namespace Vendor {
  export class GetAll {
    static readonly type = '[Vendors] Get Vendors';
  }
  
  export class Create {
    static readonly type = '[Vendors] Create Vendor';
    constructor(public payload: VendorCreatePayload) { }
  }

  export class GetOne {
    static readonly type = '[Vendors] Get Vendor';
    constructor(public payload: string) { }
  }

  export class Update {
    static readonly type = '[Vendors] Update Vendor';
    constructor(public payload: IVendor) { }
  }

  export class Publish {
    static readonly type = '[Vendors] Publish Vendors';
    constructor(public payload: { type: PublishTypes; id?: string, refetchVendors?: boolean }) { }
  }
}

export namespace Contact {
  export class GetAll {
    static readonly type = '[Vendors] Get Contacts';
    constructor(public payload: string) { }
  }

  export class Create {
    static readonly type = '[Vendors] Create Contact';
    constructor(public payload: IContact) { }
  }

  export class Update {
    static readonly type = '[Vendors] Update Contact';
    constructor(public payload: IContact) { }
  }
}

export namespace SubVendor {
  export class GetAll {
    static readonly type = '[Vendors] Get Sub-Vendors';
    constructor(public payload: string) { }
  }
  
  export class Create {
    static readonly type = '[Vendors] Create Sub-Vendor';
    constructor(public payload: ISubVendor) { }
  }

  export class Update {
    static readonly type = '[Vendors] Update Sub-Vendor';
    constructor(public payload: ISubVendor) { }
  }

  export class Delete {
    static readonly type = '[Vendors] Delete Sub-Vendor';
    constructor(public payload: string) { }
  }

  export class AddExisting {
    static readonly type = '[Vendors] Add Existing Sub-Vendor';
    constructor(public payload: string) { }
  }
}

export namespace Restriction {
  export class GetAll {
    static readonly type = '[Vendors] Get Restrictions';
  }

  export class Create {
    static readonly type = '[Vendors] Create Restriction';
    constructor(public payload: IRestriction) { }
  }

  export class Update {
    static readonly type = '[Vendors] Update Restriction';
    constructor(public payload: IRestriction) { }
  }

  export class Delete {
    static readonly type = '[Vendors] Delete Restriction';
    constructor(public payload: string) { }
  }
}

export namespace PriceCode {
  export class GetAll {
    static readonly type = '[Vendors] Get Price Codes';
  }

  export class Create {
    static readonly type = '[Vendors] Create Price Code';
    constructor(public payload: IPriceCode) { }
  }

  export class Update {
    static readonly type = '[Vendors] Update Price Code';
    constructor(public payload: IPriceCode) { }
  }

  export class Delete {
    static readonly type = '[Vendors] Delete Price Code';
    constructor(public payload: string) { }
  }
}

export namespace MultiUser {
  export class GetDetails {
    static readonly type = '[Vendors] Get Multi User Details';
  }

  export class CreatePricing {
    static readonly type = '[Vendors] Create Multi User Pricing';
    constructor(public payload: IMultiUserPricing) { }
  }

  export class UpdatePricing {
    static readonly type = '[Vendors] Update Multi User Pricing';
    constructor(public payload: IMultiUserPricing) { }
  }

  export class DeletePricing {
    static readonly type = '[Vendors] Delete Multi User Pricing';
    constructor(public payload: string) { }
  }
  
  export class CreateException {
    static readonly type = '[Vendors] Create Multi User Exception';
    constructor(public payload: IMultiUserException) { }
  }

  export class UpdateException {
    static readonly type = '[Vendors] Update Multi User Exception';
    constructor(public payload: IMultiUserException) { }
  }

  export class DeleteException {
    static readonly type = '[Vendors] Delete Multi User Exception';
    constructor(public payload: string) { }
  }
}
