import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IChangesHistory } from 'app/shared/interfaces';

@Component({
  selector: 'app-changes-history-dumb',
  templateUrl: './changes-history.component.html',
  styleUrls: ['./changes-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangesHistoryComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set history(history: IChangesHistory[]) {
    if (history?.length) {
      this.dataSource = new MatTableDataSource(history);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    this._history = history;
  };
  @Input() loading: boolean;

  dataSource: MatTableDataSource<IChangesHistory>;
  displayedColumns = [ 'author', 'event', 'created_at',  'object_changes' ];
  _history: IChangesHistory[] = [];

  getChanges(changes: { [key: string]: [ string, string ] }): string[] {
    const fields = Object.keys(changes);
    return fields.map(field => (`${field} changed from '${changes[field][0]}' to '${changes[field][1]}'`));
  }

  get history(): IChangesHistory[] {
    return this._history;
  }

}
