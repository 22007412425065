import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'invoice-sa-cc-info',
  templateUrl: './sa-cc.html'
})
export class InvoiceSaCreditCardComponent implements OnInit{
  constructor() { }
  ngOnInit() { }
}
