import { Component, Input, OnInit } from '@angular/core';
import { AppDataService } from "../../../_services/app_data.service";

@Component({
  selector: 'css-invoice-remit-info',
  templateUrl: './css.html'
})
export class CssInvoiceRemitComponent implements OnInit{
  constructor(
    public appDataService: AppDataService
  ) { }
  ngOnInit() { }
}
