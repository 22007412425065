import { Mappable } from './mappable';
import { Invoice } from './invoice';
import { LineItem } from './line_item';
import { Address } from './address';
import { EmailAddress } from './email_address';
import { PhoneNumber } from './phone_number';
import { CreditCard } from './credit_card';
import { CurrencyPipe } from '@angular/common';

export class Order extends Mappable {
  id: number = 0;
  date: Date;
  currency: string;
  gateway: string;
  membership: boolean = false;
  origin_code: string;
  origin_code_description: string;
  discount_rate: number = 0;
  first_name: string;
  last_name: string;
  type: string;
  person_id: number;
  ship_method: string;
  payment_method: string;
  status: string;
  order_status_code: string;
  activity_log_count: number = 0;
  coupon: string;
  coupon_amount: number;
  billing_address: Address = new Address();
  shipping_address: Address = new Address();
  shipping_email_address: EmailAddress = new EmailAddress();
  billing_email_addresses: EmailAddress[]=[];
  shipping_phone: PhoneNumber = new PhoneNumber();
  billing_phone: PhoneNumber = new PhoneNumber();
  credit_card: CreditCard;
  invoices: Invoice[] = [];
  _unshipped_line_items: LineItem[] = [];
  line_items: LineItem[] = [];
  calculated_total: number;
  shipping_charge: number;
  tax: number;
  tax_xml: string;
  tax_exempt: boolean;
  reseller: boolean;
  billing_note: string;
  prevent_emailed_invoice: boolean;
  order_reshipped: boolean;
  vat_number: string;
  payment_method_code: string;
  shipping_instructions: string;
  po_number: string;
  exchange_rate: number;
  modified_by: string;
  created_by: string;
  full_coupon_amount: number;
  coupon_percentage:number;
  old_quote: boolean;
  can_complete_fulfilled_order: boolean;
  subtotal: number;


  unshipped_line_items(): LineItem[] {
    if (this.wire_transfer_item() !== null) {
      return this._unshipped_line_items.filter((li) => li.id !== this.wire_transfer_item().id);
    } else {
      return this._unshipped_line_items;
    }
  }

  sorted_unshipped_line_items() {
    return this.sorted_line_items(this.unshipped_line_items());
  }

  unshipped_item_total() {
    return this.unshipped_line_items().reduce((sum, item) =>  sum + (item.quantity * item.price), 0.0);
  }

  sellable_line_items(): LineItem[] {
    var items = this.line_items
    if (this.wire_transfer_item() !== null) {
      items = items.filter((li) => li.id !== this.wire_transfer_item().id);
    }
    if (this.shipping_item() !== null) {
      items = items.filter((li) => li.id !== this.shipping_item().id);
    }
    return items;
  }

  sorted_sellable_line_items() {
    return this.sorted_line_items(this.sellable_line_items());
  }

  sellable_item_total() {
    return this.sellable_line_items().reduce((sum, item) => sum + (item.quantity * item.price), 0.0);
  }

  sorted_line_items(line_items : LineItem[]) : LineItem[] {
    return line_items.sort( (a,b) => {
      const a_name = a.item.toLowerCase();
      const b_name = b.item.toLowerCase();
      const a_frmt = a.format.toLowerCase();
      const b_frmt = a.format.toLowerCase();

      if(a_name < b_name){
        return -1;
      }
      else if (b_name < a_name){
        return 1;
      }
      else {
        if(a_frmt < b_frmt){
          return -1;
        }
        else {
          return 1;
        }
      }
    });
  }

  editing_disabled(): boolean {
    if (this.status === 'Completed' || this.status === 'Cancelled') {
      return true;
    }
    return false;
  }

  line_item_total() {
    return this.line_items.reduce((sum, item) => sum + (item.total_price()), 0.0);
  }

  get total(): number {
    if (this.calculated_total || this.calculated_total === 0) {
      return this.calculated_total;
    } else {
      return this.line_item_total();
    }
  }

  detailsValid(): boolean {
    return true;
  }

  shippingValid(): boolean {
    if (!this.shipping_address || !this.shipping_email_address || !this.shipping_phone) {
      return false;
    }
    if (!this.ship_method) {
      return false;
    }
    return true;
  }

  shipMethodValid(): boolean {
    if (this.ship_method === 'Download' && this.line_items.filter((i) =>  i.downloadable !== true).length > 0) {
      return false;
    }
    return true;
  }

  billingValid(): boolean {
    if (!this.billing_address || !this.billing_email_addresses.length || !this.billing_phone) {
      return false;
    }
    return true;
  }

  paymentValid(): boolean {
    if ((!this.payment_method) || (this.payment_method_code === 'CCF')) {
      return false;
    }
    if ((this.payment_method_code === 'CC') && (!this.credit_card)) {
      return false;
    }
    return true;
  }

  wire_transfer_item() {
    const wtf = this.line_items.find( (li) => li.item === 'MISC WIRE TRANSFER');
    return wtf ? wtf : null;
  }

  shipping_item() {
    const wtf = this.line_items.find( (li) => li.item === 'MISC SPECIAL SHIPPING');
    return wtf ? wtf : null;
  }

  wire_transfer_fee() {
    if (this.wire_transfer_item()) {
      return 1 * this.wire_transfer_item().price;
    } else {
      return 0;
    }
  }

  canBeInvoiced(): boolean {
    if (!this.shippingValid()) {
      return false;
    }
    if (!this.shipMethodValid()) {
      return false;
    }
    if (!this.billingValid()) {
      return false;
    }
    if (!this.paymentValid()) {
      return false;
    }
    if (this.sellable_line_items().length <= 0) {
      return false;
    }
    if (!this.validateSpecialShippingMethod()) {
      return false;
    }

    if (!this.validateDownloadable()) {
      return false;

    }

    return true;
  }

  canQuote(): boolean {

    if (!this.shippingValid()) {
      return false;
    }
    if (!this.shipMethodValid()) {
      return false;
    }
    if (!this.billingValid()) {
      return false;
    }
    if (this.sellable_line_items().length <= 0) {
      return false;
    }
    if (!this.validateSpecialShippingMethod()) {
      return false;
    }

    if (!this.validateDownloadable()) {
      return false;

    }

    return true;
  }

  showProforma() {
    if (this.status !== 'Quote') {
      return false;
    }
    if (!this.canQuote()) {
      return false;
    }
    return true;
  }

  canBeOpened(): boolean {
    if (this.status !== 'Quote') {
      return false;
    }
    if (!this.canBeInvoiced()) {
      return false;
    }

    return true;
  }

  validateDownloadable() {

    if (this.sellable_line_items().length > 0) {

    if (this.line_items.find((li) => li.item === 'MISC SPECIAL SHIPPING')) {

      if (!this.line_items.find((li) => li.downloadable === false)) {
        return false;
      }
    }
  }

    return true;
  }

  validateSpecialShippingMethod(): boolean {
    if (this.line_items.find((li) => li.item === 'MISC SPECIAL SHIPPING' && this.shipping_instructions !== 'W')) {
      return false;
    }
    return true;
  }

  checkOrderTotal(personDetails){

    if(this.payment_method_code === 'INV'){
      var limit = personDetails.remaining_credit_limit
      if(this.currency === "AUD"){
        limit = personDetails.remaining_credit_limit_aud
      }

      if(limit < this.calculated_total && this.calculated_total > 0.0){
        return false;
      }

    }
    return true;
  }

  getCurrency(value,invoiceOrder){
    let cp = new CurrencyPipe('en');
    let usdValue = cp.transform(value, 'USD', 'symbol', '1.2-2');
    if(invoiceOrder.currency == 'CAD' || invoiceOrder.currency == 'AUD')
      return      usdValue+' '+invoiceOrder.currency;
    else
      return cp.transform(value, invoiceOrder.currency, 'symbol', '1.2-2');
  }

  localCurrency(value,order){
    if(value) {
      let cp = new CurrencyPipe('en');
      let usdValue = cp.transform(value, 'USD', 'symbol', '1.2-2');
      if (order.currency !== 'EUR')
        return      usdValue+' '+order.currency;
      else
        return  cp.transform(value, order.currency, 'symbol', '1.2-2')+' '+order.currency;
    } else {
      return   undefined;
    }
   }

  isAUD(){
    return this.currency === 'AUD';
  }

  isLocalCurrency(amount, order) {
    let cp = new CurrencyPipe('en');
    let er = order.exchange_rate*amount;
    let usdValue = cp.transform(er, 'USD', 'symbol', '1.2-2');
    if(order.currency !== 'EUR')
      return      usdValue+' '+order.currency;
    else
      return  cp.transform(er, order.currency, 'symbol', '1.2-2')+' '+order.currency;
}

    constructor() { super();

  }
}
