
import {throwError as observableThrowError,  Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Payment } from '../../_models/payment';
import { Invoice } from '../../_models/invoice';
import { Order } from '../../_models/order';
import { CreatePayment } from '../_interfaces/create-payment';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class PaymentService {
  private baseUrl = `${environment.api_host}/apis/forms/v1/payments/`;
  private userBaseUrl = `${environment.api_host}/apis/forms/v1/persons/`;

  constructor(
    private _client: HttpClient
  ) { }
  loadById(id: number): Observable<Payment> {
    return this._client.get<Payment>(this.baseUrl + id)
      .pipe(map(data => new Payment().fromJSON(data)));
  }
  loadStub(): Observable<Payment> {
    const p: Payment = new Payment();
    p.reference_date = new Date();
    return of(p);
  }

  loadStubInvoice(invoiceid:number):Observable<Payment>{
    const p: Payment = new Payment();
    p.reference_date = new Date();
    p.search_invoice_id = invoiceid;
    return of(p);
  }
  createPayment(payload: CreatePayment): Observable<Payment> {
    return this._client.post(this.baseUrl, payload)
    .pipe(map((data) => <Payment>data));
  }

  updatePayment(payload: CreatePayment,PaymentId:number): Observable<Payment> {
    return this._client.put(this.baseUrl+PaymentId, payload)
    .pipe(map((data) => <Payment>data));
  }
  openInvoices(personId: number): Observable<Invoice[]> {
    const invoices = [];
    invoices.push(<Invoice>{ id: 1, order: <Order>{ id: 2 } });
    return of(invoices);
  }

  get_invoice_details(prsn_id): Observable<any> {
    return this._client.get(`${this.userBaseUrl}/${prsn_id}/invoices/open_invoices`)
    .pipe(map(success => success),
      catchError(error => observableThrowError(error.json().errors || 'Server error')));
  }

  get_misc_trans_details(prsn_id) {
    return this._client.get(`${environment.api_host}/apis/forms/v1/persons/${prsn_id}/miscellaneous_transactions/debits`).pipe(map(
      success => {
        return success;
      }));
  }
  makeInvoicePayment(data: any, PaymentId: number) {

    return this._client.post(this.baseUrl + `${PaymentId}/apply_invoice_payments`, data)
    .pipe(map(success => success),
      catchError(error => 'Somthing went wrong'));

  }

  makeMiscDebitPayment(data: any, PaymentId: number) {

    return this._client.post(this.baseUrl + `${PaymentId}/apply_miscellaneous_debits_payments`, data)
    .pipe(map(success => success),
    catchError(error => 'Somthing went wrong'));

  }
  getPaymentDetails(PaymentId: number) {

    return this._client.get(this.baseUrl + `${PaymentId}`)
    .pipe(map(success => success),
    catchError(error => 'Somthing went wrong'));

  }

  get_applied_invoice_details(PaymentId): Observable<any> {
    return this._client.get(`${this.baseUrl}/${PaymentId}/applied_invoices`)
    .pipe(map(success => success),
    catchError(error => observableThrowError(error.json().errors || 'Server error')));
  }
  get_applied_misc_details(PaymentId): Observable<any> {
    return this._client.get(`${this.baseUrl}/${PaymentId}/applied_miscellaneous_debits`)
    .pipe(map(success => success),
    catchError(error => observableThrowError(error.json().errors || 'Server error')));
  }

  deletePayment(PaymentId): Observable<any> {
    return this._client.delete(`${this.baseUrl}${PaymentId}`)
    .pipe(map(success => success),
    catchError(error => observableThrowError(error.json().errors || 'Error in deleting the payment')));
  }


}
