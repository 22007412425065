import { Mappable } from './mappable';

export class CreditCard extends Mappable {
  id: number;
  name: string;
  default: boolean;
  type: string;
  card_type_name: string;
  last_four_digits: string;
  exp_date_month: string;
  exp_date_year: string;
  is_expired: boolean;
  cvc: string;
  token: string;
  mask: string;

  constructor() { super(); }

  toString(): string {
    return ['name', 'token', 'exp_date_month', 'exp_date_year']
      .reduce((sum, n) => sum + `${this[n]}`, '');
  }
  equals(c: CreditCard): boolean {
    return !!c && (this.toString() === c.toString());
  }

}
