import { Component, OnInit ,Input,ViewChild} from '@angular/core';
import { PaymentService } from '../accounts/_services/payment.service';
import { CreditCardService } from '../accounts/_services/credit_card.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-payment-transaction',
  templateUrl: './payment-transaction.component.html',
  styleUrls: ['./payment-transaction.component.scss']
})
export class PaymentTransactionComponent implements OnInit{
  payment:any = [];
  invoice:any = [];
  miscDetails:any  =[];
  creditDetails:any =[];
  public _validPaymentTypes = {'CK':'Check', 'CC' : 'Credit Card', 'WTO' :'Wire Transfer Order',
                              'ICTI' :'Intercompany Transaction In', 'OLCC':'Offline Credit Card Payment', 'PTI':'Payment Transfer In'};


  @Input() payment_id: number;
  @Input() dialogRef: MatDialogRef<any>;
  constructor(
    private _paymentService: PaymentService,
    private _ccService: CreditCardService,
  ) { }

  ngOnInit() {
    this._paymentService.getPaymentDetails(this.payment_id).subscribe((result) => {
       this.payment =result;
       if(this.payment.method == 'CC' && this.payment.cc_id != null){
         this._ccService.getCreditCardById(this.payment.cc_id).subscribe((result)=>{
              this.creditDetails =result;
          });
       }
    });

    this._paymentService.get_applied_invoice_details(this.payment_id).subscribe((result)=>{
      this.invoice= result;
    });

    this._paymentService.get_applied_misc_details(this.payment_id).subscribe((result)=>{
      this.miscDetails =result;
    });

  }

  paymentDisplayCurrency() {
    if(this.payment.currency == "AUD"){
      return "AUD";
    }
    return "USD";
  }

}
