import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { VendorListItem } from 'app/shared/interfaces';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-vendor-search',
  templateUrl: './vendor-search.component.html',
  styleUrls: ['./vendor-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorSearchComponent implements OnInit {

  @Input() vendors: VendorListItem[] = [];

  selectedVendor: VendorListItem;
  filteredVendors$: Observable<VendorListItem[]>;
  organizationId: string;
  organizationName: string;
  publisherCode: string;
  search = new FormControl();

  ngOnInit() {
    this.filteredVendors$ = this.search.valueChanges.pipe(
      startWith([]),
      map(value => {
        if (value?.length <= 2) {
          return [];
        }
        return this._filter(value);
      })
    );
  }

  _filter(value: string): VendorListItem[] {
    const filterValue = value.toLowerCase();
    return this.vendors.filter(v => {
      return (
        v.vendor_id.toLowerCase().includes(filterValue) ||
        v.vendor_name.toLowerCase().includes(filterValue) ||
        v.publisher_code?.toLowerCase().includes(filterValue)
      );
    });
  }

  onVendorSelect(data: MatAutocompleteSelectedEvent) {
    const vendor = this.vendors.find(v => v.vendor_id === (data.option.value as string).split(' ')[0]);
    this.selectedVendor = vendor;
    this.organizationId = vendor.vendor_id;
    this.organizationName = vendor.vendor_name;
    this.publisherCode = vendor.publisher_code;
  }

  vendorTransform(v: VendorListItem): string {
    return `${v.vendor_id} - ${v.vendor_name}${v.publisher_code ? ' - ' + v.publisher_code : ''}`;
  }

}
