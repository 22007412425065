import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';




@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.css']

})
export class StyleguideComponent implements OnInit {
  tiles = [
    {text: 'One', cols: 2, rows: 1, color: 'purple'},
    {text: 'Two', cols: 2, rows: 2, color: 'green'},
    {text: 'Three', cols: 2, rows: 1, color: 'gray'},
    {text: 'Four', cols: 4, rows: 1, color: '#DDBDF1'},
  ];


  sampleData2 = [
    { 'id': '1', 'name': 'nidhi', 'progress': '10', 'color': 'Mia' },
    { 'id': '2', 'name': 'Jack I.', 'progress': '82', 'color': 'fuchsia' },
    { 'id': '3', 'name': 'Isla C.', 'progress': '47', 'color': 'red' },
    { 'id': '4', 'name': 'Cora V.', 'progress': '43', 'color': 'maroon' },
    { 'id': '5', 'name': 'Asher T.', 'progress': '41', 'color': 'aqua' },
    { 'id': '6', 'name': 'Arthur A.', 'progress': '36', 'color': 'yellow' },
    { 'id': '7', 'name': 'Atticus M.', 'progress': '2', 'color': 'navy' },
    { 'id': '8', 'name': 'Oliver I.', 'progress': '34', 'color': 'navy' },
    { 'id': '9', 'name': 'Isla L.', 'progress': '43', 'color': 'lime' },
    { 'id': '10', 'name': 'Cora M.', 'progress': '50', 'color': 'green' }
  ];

  data=[
    {name:'Pradeep',age:24,Nationality:'India'},
    {name:'Nidhi',age:24,Nationality:'India'},
    {name:'Murthy',age:24,Nationality:'India'},
    {name:'LMS',age:24,Nationality:'India'}
  ]
  ;  accounts = [
    {
      name: 'Master Account',
      status: 'active'
    },
    {
      name: 'Testaccount',
      status: 'inactive'
    },
    {
      name: 'Hidden Account',
      status: 'unknown'
    }
  ];
  data2=[
    {name:'Pradeep',age:24,Nationality:'India'},
    {name:'Nidhi',age:24,Nationality:'India'},
    {name:'Murthy',age:24,Nationality:'India'},
    {name:'LMS',age:24,Nationality:'India'},
    {name:'LMp',age:24,Nationality:'India'}

  ];

  constructor() {

  }



  //Table Related Functionality
  @ViewChild(MatPaginator) paginator: MatPaginator;
  sampleData = sampleData;
  displayedColumns = ['userId', 'userName', 'progress', 'default' , 'color', 'action'];


  displayedColumns2 = ['default' , 'name', 'age', 'Nationality',  'actions'];
  data3=[
    {name:'Pradeep',age:24, Nationality:'India'},
    {name:'Nidhi',age:24, Nationality:'India'},
    {name:'Murthy',age:24, Nationality:'India'},
    {name:'LMS',age:24,Nationality:'India'},
    {name:'LMp',age:24,Nationality:'India'}

  ];

  exampleDatabase = new ExampleDatabase();
  dataSource: ExampleDataSource | null;

  columnsForCustomTable = [ 'id', 'name', 'default' , 'progress', 'color' , 'actions'];



  ngOnInit() {
    this.dataSource = new ExampleDataSource(this.exampleDatabase);
  }
}

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}
export const sampleData = [
  { 'id': '1', 'name': 'nidhi',  'progress': '10', 'color': 'Mia' },
  { 'id': '2', 'name': 'Jack I.', 'progress': '82', 'color': 'fuchsia' },
  { 'id': '3', 'name': 'Isla C.', 'progress': '47', 'color': 'red' },
  { 'id': '4', 'name': 'Cora V.', 'progress': '43', 'color': 'maroon' },
  { 'id': '5', 'name': 'Asher T.', 'progress': '41', 'color': 'aqua' },
  { 'id': '6', 'name': 'Arthur A.', 'progress': '36', 'color': 'yellow' },
  { 'id': '7', 'name': 'Atticus M.', 'progress': '2', 'color': 'navy' },
  { 'id': '8', 'name': 'Oliver I.', 'progress': '34', 'color': 'navy' },
  { 'id': '9', 'name': 'Isla L.', 'progress': '43', 'color': 'lime' },
  { 'id': '10', 'name': 'Cora M.', 'progress': '50', 'color': 'green' }
];

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<UserData[]> = new BehaviorSubject<UserData[]>(sampleData);
  get data(): UserData[] { return this.dataChange.value; }

}


/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
export class ExampleDataSource extends DataSource<any> {
  constructor(private _exampleDatabase: ExampleDatabase) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<UserData[]> {
    return this._exampleDatabase.dataChange;
  }

  disconnect() { }
}




