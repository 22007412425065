
import {throwError as observableThrowError, Observable,  of ,  BehaviorSubject ,  Subject ,  ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PricingService } from './pricing.service';
import { OrderService } from './order.service';
import {LineItem} from '../_models/line_item';
import {Order} from '../_models/order';
import { Product } from '../_models/product';
import { ProductItem } from '../_models/product_item';
import {environment} from '../../environments/environment';
import set = Reflect.set;
import { map } from 'rxjs/operators';

export class CartEvent {
  event_type: string;
  item: LineItem;
  quantity: number;

}

class Cart {
  currency = 'USD';
  gateway = 'css';
  membership = false;
  account_id: number = null;
  items: LineItem[] = [];
}

@Injectable()
export class ShoppingService {
  _order: Order = null;
  _observableCart: Subject<CartEvent>;
  _cart: Cart = new Cart();
  private orderUrl = `${environment.api_host}/apis/forms/v1/orders`;
  private subscriptionOrderUrl = `${environment.api_host}/apis/forms/v1/subscription_orders`;


  constructor(
    private http: HttpClient,
    private pricingService: PricingService,
    private orderService: OrderService,
  ) {
    this._observableCart = new Subject();
    this._observableCart.subscribe(
      success => {
        console.log("Change: ",success.event_type, success.item);
        switch (success.event_type) {
          case 'AddItem': {
            this._cart.items.push(success.item);
            break;
          }
          case 'RemoveItem': {
            const i = this._cart.items.findIndex((i) => i.prdd_id === success.item.prdd_id);
            if (i !== -1) {
              this._cart.items.splice(i, 1);
            }
            break;
          }
          case 'RemoveCart': {
            this._cart = new Cart();
            this._order = null;
            break;
          }
        };
        localStorage.setItem('_cart', JSON.stringify(this._cart));
      }
    );
    if (localStorage.getItem('_cart') !== null) {
      // Stored cart - load it and apply it.
      this._cart = JSON.parse(localStorage.getItem('_cart'));
    }
  }

  copyRetailOrder(order_id: number) {
    return this.http.post<Order>(environment.api_host + "/apis/forms/v1/orders/"+order_id+"/copy_order", {}).pipe(map(
      success => {
        this._order = new Order().fromJSON(success);
      }
    ));
  }

  createRetailOrder(person_id: number, include_cart: boolean) {
    const post_object = { account_id: person_id, items: []};
    if (include_cart) {
      post_object.items = this._cart.items;
    }
    return this.http.post<Order>(this.orderUrl, post_object).pipe(map(
      success => {
        this._order = new Order().fromJSON(success);
      }
    ));
  }

  createSubscriptionOrder(person_id: number) {
    const post_object = { account_id: person_id };
    return this.http.post<Order>(this.subscriptionOrderUrl, post_object).pipe(map(
      success => {
        this._order = new Order().fromJSON(success);
      }
    ));
  }

  get currency(): string {
    if (this.order !== null) {
      return this.order.currency;
    } else {
      return this._cart.currency;
    }
  }

  get gateway(): string {
    if (this.order !== null) {
      return this.order.gateway;
    } else {
      return this._cart.gateway;
    }
  }

  get membership(): boolean {
    if (this.order !== null) {
      return this.order.membership;
    } else {
      return this._cart.membership;
    }
  }

  get isSubscriptionOrder(): boolean {
    return this.order && this.order.type === "Subscription Order";
  }

  get routerDestination(): string {
    if (this.order !== null) {
      return `/orders/${this.order.id}/items`;
    } else {
      return '/shopping/cart';
    }
  }

  get total(): number {
    if (this.order !== null) {
      return this.order.total;
    } else {
      return this._cart.items.reduce( (sum, i) => (sum = sum + (i.price * i.quantity)), 0);
    }
  }


  get order(): Order {
    return this._order;
  }

   setActiveOrder(o: Order) {
    this._order = o;
  }

  set gateway(v: string) {
    this._cart.gateway = v;
  }

  set membership(v: boolean) {
    this._cart.membership = v;
  }

  items(): Observable<LineItem[]> {
    if (this.order === null) {
      return of(this._cart.items);
    } else {
      return of(this._order.line_items);
    }
  }

  get item_count(): number {
    if (this.order !== null) {
      return this.order.sellable_line_items().length;
    } else {
      return this._cart.items.length;
    }
  }


  makeLineItem(pi: ProductItem, quantity: number): LineItem {
    const li = new LineItem();
    li.prdd_id = pi.id;
    li.quantity = quantity;
    if (pi.downloadable && quantity > 1) {
      const mup = pi.multi_user_prices.filter( (m) => m.quantity == quantity)[0];
      console.log('Found mup: ', pi.multi_user_prices);
      li.price = mup.price;
      li.list_price = mup.list_price;
      li.cost = mup.cost;
    } else {
      li.price = pi.price;
      li.list_price = pi.list_price;
      li.cost = pi.cost;
    }
    li.product_id = pi.product_id;
    li.vendor_id = pi.vendor_id;
    li.fulfillment_code = pi.fulfillment_method_code;
    li.vendor_payment_method = pi.vendor_payment_method;
    li.quantity_back_ordered = li.quantity;
    li.weight = pi.weight;
    li.format_code = pi.format_code;
    return li;
  }

  canAddItem(pi: ProductItem): boolean {
    if (this._order === null) {
      if (this._cart.items.filter((i) => i.prdd_id === pi.id).length > 0) {
        return false;
      }
      if ((pi.format_code === 'PLUS') && (this._cart.items.filter(
          (i) => i.prdd_id === pi.plus_bundle.print || i.prdd_id === pi.plus_bundle.pdf).length > 0)) {
        return false;
      }
    } else {
      if (this._order.line_items.filter( (i) => i.prdd_id === pi.id ).length > 0) {
        return false;
      }
      if ((pi.format_code === 'PLUS') && (this._order.line_items.filter(
          (i) => i.prdd_id === pi.plus_bundle.print || i.prdd_id === pi.plus_bundle.pdf).length > 0)) {
        return false;
      }
    }
    return true;
  }

  addPlusItem(pi: ProductItem, quantity: 1) {
    this.pricingService.getPricingForProduct(pi.product_id, this.gateway, this.currency, this.membership).subscribe(
      success => {
        success.items.filter( i => i.id === pi.plus_bundle.pdf || i.id === pi.plus_bundle.print).map(
          item => {
            var old_price = item.price;
            item.price = 0;
            this.addItem(item, 1);
            item.price = old_price;
          }
        );
      }
    );
  }

  addItem(pi: ProductItem, quantity: number) {
    if (pi.format_code === 'PLUS') {
      this.addPlusItem(pi, 1);
    }
    if (this._order) {
      this.addItemToOrder(this.makeLineItem(pi, quantity));
    } else {
      const ce = this.buildAddItemEvent();
      ce.item = this.makeLineItem(pi, quantity);
      this._observableCart.next(ce);
    }
  }

  addItemToOrder(item) {
    this.orderService.addItem(this.order.id, item).subscribe(
      success => this.setActiveOrder(this.orderService.order)
    );
  }

  removeItem(item: LineItem) {
    if (this._order) {
      this.removeItemFromOrder(item);
    } else {
      const ce = this.buildRemoveItemEvent();
      ce.item = item;
      if (item.format_code === 'PLUS') {
        this.pricingService.getPricingForProduct(item.product_id, this.gateway, this.currency, this.membership).subscribe(
          success => {
            const product_item = success.items.find( (i) => i.id === item.prdd_id);
            this.removeItem(this._cart.items.find( (i) => i.prdd_id === product_item?.plus_bundle.print));
            this.removeItem(this._cart.items.find( (i) => i.prdd_id === product_item?.plus_bundle.pdf));
          }
        );
      }
      this._observableCart.next(ce);
    }
  }

  removeItemFromOrder(item) {
    this._order._unshipped_line_items = this._order._unshipped_line_items.filter( i => i !== item);
    this.http.delete(`${this.orderUrl}/${this._order.id}/line_items/${item.id}`);
  }

  registerOrder(id: number) {
    this.loadOrder(id).subscribe(
      success => this._order = success
    );
  }

  loadOrder(id: number): Observable<Order> {
    return this.http.get<Order>(this.orderUrl + '/' + id).pipe(map(
      success => new Order().fromJSON(success),
      error => observableThrowError(error.json().error || 'Server error')));
  }

  saveOrder() {
    return this.http.put<Order>(`${this.orderUrl}/${this._order.id}`, this._order).pipe(map(
      success => this._order = new Order().fromJSON(success)
    ));
  }

  buildAddItemEvent() {
    const ce = new CartEvent();
    ce.event_type = 'AddItem';
    return ce;
  }

  buildRemoveItemEvent() {
    const ce = new CartEvent();
    ce.event_type = 'RemoveItem';
    return ce;
  }

  buildRemoveCartEvent() {
    const ce = new CartEvent();
    ce.event_type = 'RemoveCart';
    return ce;
  }

  recalculatePricing() {
    this._cart.items.forEach( (li) => {
      if (li.price > 0) {
        this.pricingService.getPricingForProduct(li.product_id, this._cart.gateway, this._cart.currency, this._cart.membership).subscribe(
          success => {
            const addEvent = this.buildAddItemEvent();
            addEvent.item = this.makeLineItem(success.items.find((i) => i.id === li.prdd_id), li.quantity);
            if (addEvent.item) {
              const removeEvent = this.buildRemoveItemEvent();
              removeEvent.item = li;
              this._observableCart.next(removeEvent);
              this._observableCart.next(addEvent);
            }
          }
        );
      }
    });
  }

  removeCart() {
    this._observableCart.next(this.buildRemoveCartEvent());
  }
}
