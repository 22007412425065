import { Mappable } from './mappable';

interface MultiUserPrice {
  quantity: number;
  price: number;
  list_price: number;
  cost: number;
}

interface PrintPlusPdfBundle {
  print: number;
  pdf: number;
}

export class ProductItem extends Mappable {
  id: number = 0;
  product_id: number;
  language: string;
  free_download: boolean;
  weight: number;
  fulfillment_method: string;
  fulfillment_method_code: string;
  availability: string;
  sku: string;
  vendor_payment_method: string;
  quantity_on_hand: number;
  currency: string;
  vendor_id: number;
  format: string;
  format_code: string;
  isbn: string;
  price: number;
  list_price: number;
  cost: number;
  page_count: number;
  shipping_info: string;
  multi_user_quantities: number[];
  multi_user_prices: MultiUserPrice[];
  downloadable: boolean;
  plus_bundle: PrintPlusPdfBundle;
  redline: boolean;
  needs_sync: boolean;

  format_description() {
    let desc = '';
    if (this.format_code === 'PLUS') {
      desc = 'Print + PDF';
    } else if (this.downloadable) {
      desc = 'Download';
    } else {
      desc = 'Print';
    }
    return desc;
  }

  constructor() { super() };
}
