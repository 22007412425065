import { Component, Inject, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { EntitlementContent } from '../../_models/entitlement_content';
import {FormBuilder, FormGroup } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shared-ent-content-log',
  templateUrl: './ent-content-log.component.html',
  styleUrls: ['./ent-content-log.component.scss']
})
export class EntContentLogComponent implements OnInit, AfterViewInit {

  @ViewChild('dataTable') table: ElementRef;
  form: FormGroup;
  sortedData;
  content: EntitlementContent;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { content: EntitlementContent },
    private mdDialogRef: MatDialogRef<EntContentLogComponent>,
    private _fb: FormBuilder
  ) {

  }

  ngOnInit() {

    this.content = this.data.content;
    this.createForm();
    this.sortedData = this.content.log_entries;

  }
  ngAfterViewInit() {
    let el: HTMLElement = this.table.nativeElement as HTMLElement;
    this.sortData(el);
  }

  private createForm() {
    this.form = this._fb.group({
      fileName: [{ value: this.content.pdf.pdf_file_name, disabled: true }],
      availablePrints: [{ value: this.content.available_prints, disabled: true }],
      downloadCount: [{ value: this.content.download_count, disabled: true }],
      openCount: [{ value: this.content.open_count, disabled: true }],
      printCount: [{ value: this.content.print_count, disabled: true }],
      transferCount: [{ value: this.content.transfer_count, disabled: true }],
    });
  }

  sortData(sort: any) {
    if (sort.active == undefined || sort.active == '') {
      sort.active = "created_at";
    }
    if (sort.direction == undefined) {
      sort.direction = 'desc';
    }
    const data = this.content.log_entries.slice();
    if (!sort.active || sort.direction == '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      let isAsc = sort.direction == 'asc';
      switch (sort.active) {
        case 'operation': return compare(a.operation, b.operation, isAsc);
        case 'created_by': return compare(a.created_by, b.created_by, isAsc);
        case 'created_at': return compare(a.created_at, b.created_at, isAsc);
        case 'description': return compare(a.description, b.description, isAsc);
        default: return 0;
      }
    });
  }

}
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
