import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { CreateUserComponent } from '../create-user/create-user.component';
import { EventEmitter } from 'protractor';
import { AddressService } from 'app/_services/contact-information/address.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-validated-address',
  templateUrl: './validated-address.component.html',
  styleUrls: ['./validated-address.component.scss']
})
export class ValidatedAddressComponent implements OnInit {

  @Input() address_type: string;
  @Input() person_id: number;
  @Input() dialogRef: MatDialogRef<any>;
  @Input() fromCreate: boolean;
  @Input() addressData: any;
  static_address: any = {} ;
  valid_addresses: any = [];
  entered_address: any;


  constructor (private addressService: AddressService) {}

  ngOnInit() {
    this.static_address = this.addressData['static_data'];
    this.valid_addresses = this.addressData['valid_addresses'];
    this.entered_address = this.addressData['addressData'];
  }

  saveOriginalAddress() {
    this.entered_address['not_validated'] = true
    this.saveAddress(this.entered_address);
  }

  saveValidatedAddress() {
    this.saveAddress(this.valid_addresses[0]);
  }

  saveAddress(address) {
    var adrs_to_save = { ...this.static_address, ...address };

    if (this.fromCreate) {
      this.addressService.create(adrs_to_save, this.address_type).subscribe( response => {
        this.successfulClose();
      }, error => {
        alert("Got an error - check your console");
      });
    } else {
      this.addressService.update(adrs_to_save, this.address_type).subscribe( response => {
        this.successfulClose();
      }, error => {
        alert("Got an error - check your console");
      });
    }
  }

  successfulClose() {
    let data = [];
    data['status'] = true;
    this.dialogRef.close(data);
  }

  cancel() {
    let data = [];
    data['status'] = false;
    data['addressData'] = this.addressData['addressData'];
    this.dialogRef.close(data);
  }

}
