import {Component, Input, OnInit} from "@angular/core";
import {SpinnerService} from "../../_services/spinner.service";

@Component({
  selector: 'custom-spinner',
  templateUrl: './custom.spinner.component.html',
  styleUrls: ['./custom.spinner.component.scss']
})
export class CustomSpinnerComponent implements OnInit {
  public spinnerState: any;
  public spinnerTitle = 'Loading ...';
  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.spinnerStateStream$.subscribe(state => {
      this.spinnerState = state;
    });
  }
  ngOnInit() {
    this.spinnerState = this.spinnerService.getSpinnerState();
  }

}
