import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {SpinnerService} from "../_services/spinner.service";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './default_layout.component.html',
  styleUrls: ['./default_layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  searchForm: FormGroup;
  current_user: object;
  spinning: boolean = false;
  constructor(private router: Router, private fb: FormBuilder, private spinnerService: SpinnerService) {
    this.searchForm = this.fb.group({ query: ['', Validators.required] });
  }

  @ViewChild('throbber') throbber: ElementRef;
  ngOnInit() {
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
    this.spinnerService.subscribe();
  }

  isTesting() : boolean {
    return environment.api_host.toString().match(/janus.prod\-techstreet\.com/) == null;
  }

  vulcanNameDisplay() : string {
    if(this.isTesting()){
      return 'Vulcan - Testing';
    }else{
      return 'Vulcan - Production';
    }
  }

  search() {
    this.router.navigate(['/search', this.searchForm.value.query]);
  }



}
