import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Action, State, StateContext } from '@ngxs/store';
import { HTTP_MESSAGES, SSO_ISSUE_REQUEST_PLACEHOLDER } from 'app/shared/constants';
import { ISSOIssuesList, ISubscription, ISubscriptionSettings } from 'app/shared/interfaces';
import { catchError, tap } from 'rxjs/operators';
import { AlertService, ErrorHandlerService, SSOIssuesService } from '../services';
import * as actions from './action-namespaces/sso-issues';

export interface SSOIssuesState {
  loading: boolean;
  subscriptions: ISubscription[];
  subscriptionSettings: ISubscriptionSettings;
  issues: ISSOIssuesList;
}

@State<SSOIssuesState>({
  name: 'sso_issues',
  defaults: {
    loading: false,
    subscriptions: [],
    issues: {
      data: [],
      pagination: SSO_ISSUE_REQUEST_PLACEHOLDER.pagination,
    },
    subscriptionSettings: null,
  }
})
@Injectable()
export class SSOIssuesStore {
  constructor(
    private readonly ssoIssuesService: SSOIssuesService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) { }

  @Action(actions.Subscription.GetAll)
  getSubscriptions(ctx: StateContext<SSOIssuesState>) {
    return this.ssoIssuesService.getSubscriptions().pipe(
      tap(subscriptions => ctx.patchState({ subscriptions })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.SSOIssue.GetAll)
  getIssues(ctx: StateContext<SSOIssuesState>, action: actions.SSOIssue.GetAll) {
    ctx.patchState({ loading: true });
    return this.ssoIssuesService.getIssues(action.payload).pipe(
      tap(issues => ctx.patchState({ issues, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.SSOIssue.UpdateStatus)
  updateStatus(ctx: StateContext<SSOIssuesState>, action: actions.SSOIssue.UpdateStatus) {
    const state = ctx.getState();
    return this.ssoIssuesService.updateStatus(action.payload).pipe(
      tap(resp => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.setState({
          ...state,
          issues: {
            ...state.issues,
            data: state.issues.data.map(i => i.id === resp.id ? { ...i, status: resp.status } : i)
          },
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.SSOIssue.AddComment)
  addComment(ctx: StateContext<SSOIssuesState>, action: actions.SSOIssue.AddComment) {
    const state = ctx.getState();
    return this.ssoIssuesService.addComment(action.payload).pipe(
      tap(resp => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.setState({
          ...state,
          issues: {
            ...state.issues,
            data: state.issues.data.map(i => i.id === resp.sso_log_id ? { ...i, comments: [ ...i.comments, resp ] } : i)
          },
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Subscription.Update)
  updateSubscription(ctx: StateContext<SSOIssuesState>, action: actions.Subscription.Update) {
    return this.ssoIssuesService.updateSubscriptionSettings(action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Subscription.GetOne)
  getSubscriptionSettings(ctx: StateContext<SSOIssuesState>, action: actions.Subscription.GetOne) {
    return this.ssoIssuesService.getSubscriptionSettings(action.payload).pipe(
      tap(subscriptionSettings => ctx.patchState({ subscriptionSettings })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.GeneralPurpose.ResetSSOIssuesField)
  resetField(ctx: StateContext<SSOIssuesState>, action: actions.GeneralPurpose.ResetSSOIssuesField) {
    const { name, value } = action.payload;
    ctx.patchState({ [name]: value });
  };

}
