import { Injectable } from '@angular/core';
import { OrderSummaryComponent } from '../order-summary/order-summary.component';
import { ProductPricingComponent } from '../shared/product-pricing/product-pricing.component';
import { PaymentTransactionComponent } from '../payment-transaction/payment-transaction.component';
import { ConfirmPlaceOrderComponent } from '../orders/retail/confirm-place-order/confirm-place-order.component';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class DialogService {

  bigDialogConfig: MatDialogConfig = {
    disableClose: false,
    hasBackdrop: true,
    backdropClass: '',
    width: '700px',
    height: '600px',
    position: {},
  };
  config: MatDialogConfig = {
    disableClose: false,
    hasBackdrop: true,
    backdropClass: '',
    width: '600px',
    height: '400px',
    position: {},
  };
  transactionDilog: MatDialogConfig = {
    disableClose: false,
    hasBackdrop: true,
    backdropClass: '',
    width: '1000px',
    height: '600px',
    position: {},
  };

  smdialog: MatDialogConfig = {
    disableClose: false,
    hasBackdrop: true,
    backdropClass: '',
    width: '500px',
    height: '300px',
    position: {},
  };

  constructor(private dialog: MatDialog) { }

  public viewOrderSummary(order_id: number) {
    let dialogRef: MatDialogRef<OrderSummaryComponent>;
    dialogRef = this.dialog.open(OrderSummaryComponent, this.bigDialogConfig);

    dialogRef.componentInstance.order_id = order_id;
    dialogRef.componentInstance.dialogRef = dialogRef;

    return dialogRef.afterClosed();

  }

  public viewProductPricing(product_id: number) {
    let dialogRef: MatDialogRef<ProductPricingComponent>;
    dialogRef = this.dialog.open(ProductPricingComponent, this.bigDialogConfig);

    dialogRef.componentInstance.product_id = product_id;
    dialogRef.componentInstance.dialogRef = dialogRef;

    return dialogRef.afterClosed();
  }
  public showTransactionHistory(payId: number) {

    let dialogRef: MatDialogRef<PaymentTransactionComponent>;
    var config = this.transactionDilog
    config["width"] = "80%";
    dialogRef = this.dialog.open(PaymentTransactionComponent, this.transactionDilog);

    dialogRef.componentInstance.payment_id = payId;
    dialogRef.componentInstance.dialogRef = dialogRef;

    return dialogRef.afterClosed();

  }

  public confirmSaveOrder() {
    let dialogRef: MatDialogRef<ConfirmPlaceOrderComponent>;
    dialogRef = this.dialog.open(ConfirmPlaceOrderComponent, this.smdialog);
    dialogRef.componentInstance.dialogRef = dialogRef;
    return dialogRef.afterClosed();
  }
}
