import { Injectable } from '@angular/core';
import { StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(
    private readonly alertService: AlertService,
  ) { }

  handleError(prefix: string, e: any, ctx?: StateContext<{ loading: boolean }>): Observable<boolean> {
    this.alertService.showAlert({
      message: this.errorMessageConstructor(prefix, e),
      type: 'ERROR',
    });
    ctx?.patchState({ loading: false });
    return of(true);
  }

  errorMessageConstructor = (prefix: string, e: any): string => {
    if (Array.isArray(e?.error?.message)) {
      return `${prefix}: ${e?.error?.message.join(' ')}`;
    }
    if (e?.error?.message) {
      return `${prefix}: ${e?.error?.message}`;
    }
    return `${prefix}`;
  }

}
