import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";

@Injectable()
export class SpinnerService {
  public spinning_count = 0;
  public _spinning_flag: boolean = false;
  private spinning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public spinnerStateSubject = new Subject<any>();
  public spinnerStateStream$ = this.spinnerStateSubject.asObservable();

  public getSpinnerState = () => this.spinnerState;
  public hideSpinner = () => this.changeSpinnerState(false);
  public showSpinner = () => this.changeSpinnerState(true);

  private spinnerState = {
    visibility: false
  };

  changeSpinnerState(visibility: boolean) {
    this.spinnerState.visibility = visibility;
    this.spinnerStateSubject.next(this.spinnerState);
  }

  start() {
    this.spinning_count = this.spinning_count + 1;
    if (this._spinning_flag === false) {
      this.spinning.next(true);
      this._spinning_flag = true;
    }
  }

  stop() {
    this.spinning_count = this.spinning_count - 1;
    if (this.spinning_count <= 0) {
      this.spinning_count = 0;
      this.spinning.next(false);
      this._spinning_flag = false;
    }
  }

  subscribe(){
    this.spinning.subscribe(
      success => {
        if (success === true) {
          this.showSpinner();
        } else {
          this.hideSpinner();
        }
      });
  }
}

