
import {throwError as observableThrowError, Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import { Headers, Response } from '@angular/http';
import { environment } from '../../environments/environment';
import { JwtHelperService} from '@auth0/angular-jwt';



@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private jwt: JwtHelperService,
  ) { }

  loggedIn() {
    if (localStorage.getItem('token') !== null) {
      return !this.jwt.isTokenExpired(localStorage.getItem('token'));
    } else {
      return false;
    }
  }


  login(email: string, password: string): Observable<void> {
    console.log('Attempting to login');
    return this.http.post(
      environment.api_host + '/apis/forms/v1/authentication',
      {email, password},
      { headers: new HttpHeaders().set('Content-Type', 'application/json')})
      .pipe(map(this.extractData),
            catchError((error, caught) => {
              return this.handleError(error);
            })

      ) as any;
  }
    private extractData(res: Response) {
      if (res && res['auth_token']) {
        localStorage.setItem('token', res['auth_token']);
      }
      return res;
    }

    private handleError (error: Response | any) {
      let errMsg: {};
      if (error instanceof HttpErrorResponse) {
        const err = error.error || JSON.stringify(error);
        errMsg = {status_code: error.status, status_text: error.statusText, message: err.error};
      } else {
        errMsg = error.message ? error.message : error.toString();
      }
      return throwError(errMsg);
    }
}
