
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CreditCard } from '../../_models/credit_card';
import { map , catchError} from 'rxjs/operators';


@Injectable()
export  class CreditCardService {
  private baseUrl = `${environment.api_host}/apis/forms/`;
  public creditCardLists: CreditCard[];
  dataChange: BehaviorSubject<CreditCard[]> = new BehaviorSubject<CreditCard[]>([]);

  constructor(
    private _client: HttpClient
  ) {}

  get data(): CreditCard[] {
    return this.dataChange.value;
  }

  getCreditCards(person_id) {
    this._client.get<CreditCard[]>(this.baseUrl + `v1/persons/${person_id}/credit_cards`)
      .subscribe(success => {
        this.dataChange.next(success.map( (v) => new CreditCard().fromJSON(v)));
      });
  }

  fetchCreditCards(person_id) : Observable<CreditCard[]> {
    return this._client.get<CreditCard[]>(this.baseUrl + `v1/persons/${person_id}/credit_cards`)
      .pipe(map(success => success.map( (v) => new CreditCard().fromJSON(v))),
      catchError(error => observableThrowError(error.json().errors || 'Server error')));
  }

  updateCreditCard(person_id, card) {
    return this._client.put(this.baseUrl + `v1/persons/${person_id}/credit_cards/${card.id}`, card).pipe(map(
      success => {}
    ));
  }

  deleteCreditCard(person_id, card) {
    return this._client.delete(this.baseUrl + `v1/persons/${person_id}/credit_cards/${card.id}`)
    .pipe(map( success => {}));
  }
  createCreditCard(person_id, card): Observable<any> {
    return this._client.post(this.baseUrl + `v1/persons/${person_id}/credit_cards`, card)
    .pipe(
        map((success) => {console.log('success posting credit card ||', success);}),
        catchError(error => Observable.throw(error.json().errors || 'Server Error'))
     );

  }


  getCreditCardById(cc_id) :Observable<any>{
    return this._client.get(this.baseUrl + `v1/credit_cards/${cc_id}`)
    .pipe(map((res)=> res),
    catchError(error => observableThrowError(error.json().errors || 'Error getting credit cards')));
  }

  getjwk(){
    console.log('in service');
    var body;

      return this._client.post(this.baseUrl +`v1/credit_cards/cybersource_token`, body)
      .pipe(map(success => success));
  }


}
