import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertComponent} from '../_directives/alert.component';
import {AddressComponent} from './address/address.component';
import {CssInvoiceHeaderComponent} from './invoice/headers/css.component';
import {AudInvoiceHeaderComponent} from './invoice/headers/aud.component';
import {SaInvoiceHeaderComponent} from './invoice/headers/sa.component';
import {CssInvoiceRemitComponent} from './invoice/remits/css.component';
import {SaInvoiceRemitComponent} from './invoice/remits/sa.component';
import {InvoiceCssCheckComponent} from './invoice/payments/css-check.component';
import {InvoiceSaCreditCardComponent} from './invoice/payments/sa-cc.component';
import {InvoiceVatsComponent} from './invoice/vat.component';
import {OrderSummaryComponent} from '../order-summary/order-summary.component';
import {ProductPricingComponent} from './product-pricing/product-pricing.component';
import {EntContentLogComponent} from './ent-content-log/ent-content-log.component';
import {DialogService} from '../_services/dialog.service';
import {CommonModule} from '@angular/common';
import {CapitalizePipe} from '../_pipes/capitalize.pipe';
import {SafePipe} from '../_pipes/safe.pipe';
import {PricePipe} from '../_pipes/price.pipe';
import {SortPipe} from '../_pipes/ordersort.pipe';

import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {CdkTableModule} from '@angular/cdk/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog'
import {
  DuplicateErrorMessagePipe,
  EmailErrorMessagePipe,
  InvalidErrorMessagePipe,
  MaxErrorMessagePipe,
  MaxlengthErrorMessagePipe,
  MinErrorMessagePipe,
  RequiredErrorMessagePipe
} from '../_pipes/error_message.pipe';

import {PriceInputComponent} from './price-input/price-input.component';
import {ResetAssigneeComponent} from './reset_assignee/reset-assignee.component';
import {EntitlementService} from 'app/orders/_services/entitlement.service';
import {ValidatedAddressComponent} from 'app/accounts/validated-address/validated-address.component';
import {MatNativeDateModule, MatRippleModule, NativeDateModule} from '@angular/material/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTableModule} from '@angular/material/table';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { TitleComponent } from './components/title/title.component';
import { ChangesHistoryComponent } from './components/changes-history/changes-history.component';
import { ChangesHistoryContainer } from './components/changes-history/changes-history.container';
import { VendorSearchComponent } from './components/vendor-search/vendor-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatDividerModule,
    MatGridListModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatAutocompleteModule,
    CdkTableModule,
    NativeDateModule,
  ],
  declarations: [
    InvoiceCssCheckComponent,
    InvoiceSaCreditCardComponent,
    CssInvoiceRemitComponent,
    SaInvoiceRemitComponent,
    InvoiceVatsComponent,
    SaInvoiceHeaderComponent,
    AudInvoiceHeaderComponent,
    CssInvoiceHeaderComponent,
    AddressComponent,
    AlertComponent,
    CapitalizePipe,
    SafePipe,
    PricePipe,
    SortPipe,
    RequiredErrorMessagePipe,
    InvalidErrorMessagePipe,
    DuplicateErrorMessagePipe,
    MaxlengthErrorMessagePipe,
    MinErrorMessagePipe,
    MaxErrorMessagePipe,
    EmailErrorMessagePipe,
    OrderSummaryComponent,
    ProductPricingComponent,
    PriceInputComponent,
    ResetAssigneeComponent,
    EntContentLogComponent,
    ValidatedAddressComponent,
    ConfirmationDialogComponent,
    NoDataComponent,
    TitleComponent,
    ChangesHistoryComponent,
    ChangesHistoryContainer,
    VendorSearchComponent,
  ],
  exports: [
    InvoiceCssCheckComponent,
    InvoiceSaCreditCardComponent,
    CssInvoiceRemitComponent,
    SaInvoiceRemitComponent,
    InvoiceVatsComponent,
    SaInvoiceHeaderComponent,
    AudInvoiceHeaderComponent,
    CssInvoiceHeaderComponent,
    AddressComponent,
    AlertComponent,
    CapitalizePipe,
    SafePipe,
    PricePipe,
    SortPipe,
    RequiredErrorMessagePipe,
    InvalidErrorMessagePipe,
    DuplicateErrorMessagePipe,
    MaxlengthErrorMessagePipe,
    MinErrorMessagePipe,
    MaxErrorMessagePipe,
    EmailErrorMessagePipe,
    MatDatepickerModule,
    PriceInputComponent,
    EntContentLogComponent,
    MatRippleModule,
    MatDividerModule,
    MatGridListModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatAutocompleteModule,
    CdkTableModule,
    NativeDateModule,
    ValidatedAddressComponent,
    NoDataComponent,
    TitleComponent,
    ChangesHistoryComponent,
    ChangesHistoryContainer,
    VendorSearchComponent,
  ],
  providers: [
    DialogService,
    EntitlementService
  ],
  entryComponents: [
    OrderSummaryComponent,
    ProductPricingComponent,
    ResetAssigneeComponent,
    EntContentLogComponent,
    ValidatedAddressComponent
  ]
})
export class SharedModule {
}
