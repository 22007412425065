import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sa-invoice-header',
  templateUrl: './sa.html',
  styleUrls: ['./sa.css']
})
export class SaInvoiceHeaderComponent implements OnInit{
  constructor() { }
  ngOnInit() { }
}
