import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../_services/auth-guard.service';
import {HomeComponent} from '../home/home.component';
import {DefaultLayoutComponent} from '../layouts/default_layout.component';
import {PrintableLayoutComponent} from '../layouts/printable-layout.component';
import {LoginLayoutComponent} from '../layouts/login_layout.component';
import {StyleguideComponent} from '../styleguide/styleguide.component';


export const appRoutes: Routes = [
  {
    path: 'authentication',
    component: LoginLayoutComponent,
    loadChildren: () => import('../authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard],
    children: [
      {path: 'home', component: HomeComponent}
    ]
  },
  {
    path: 'accounts',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../accounts/accounts.module').then(m => m.AccountsModule),
  },
  {
    path: 'data-management',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../data-management/data-management.module').then(m => m.DataManagementModule),
  },
  {
    path: 'search',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'styleguide',
    component: StyleguideComponent
  },
  {
    path: 'shopping',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../shopping/shopping.module').then(m => m.ShoppingModule),
  },
  {
    path: 'products',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'orders',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'printables',
    component: PrintableLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../printables/printables.module').then(m => m.PrintablesModule),
  },
  {
    path: 'invoices',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../invoices/invoices.module').then(m => m.InvoicesModule),
  },
  {
    path: 'vendors',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../vendors/vendors.module').then(m => m.VendorsModule),
  },
  {
    path: 'procurement',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../procurement/procurement.module').then(m => m.ProcurementModule),
  },
  {
    path: 'coupons',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../coupons/coupons.module').then(m => m.CouponsModule),
  },
  {
    path: 'gateways',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../gateways/gateways.module').then(m => m.GatewaysModule),
  },
  {
    path: 'discounts',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../discounts/discounts.module').then(m => m.DiscountsModule),
  },
  {
    path: 'sso-issues',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../sso-issues/sso-issues.module').then(m => m.SSOIssuesModule),
  },
];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    // AppModule,
    // AccountsModule,
    // DataManagementModule,
    // SearchModule,
    // ShoppingModule,
    // ProductsModule,
    // OrdersModule,
    // PrintablesModule,
    // InvoicesModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    SharedModule,
    // AppModule,
    RouterModule,
  ],
  declarations: [],
  providers: [AuthGuard]
})
export class RoutingModule {
}
