import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { PricingService } from '../../_services/pricing.service';
import { ProductItem } from '../../_models/product_item';
import { Product } from '../../_models/product';
import { LineItem } from "../../_models/line_item"

import {ShoppingService} from '../../_services/shopping.service';
import { Order } from '../../_models/order';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-product-pricing',
  templateUrl: './product-pricing.component.html',
  styleUrls: ['./product-pricing.component.css']
})
export class ProductPricingComponent implements OnInit {

  @Input() product_id: number;
  @Input() dialogRef: MatDialogRef<any>;
  addToCartForm: FormGroup;
  product: Product = new Product();
  _submitted: number;
  private orderModel = new Order();

  constructor(private pricingService: PricingService, private shoppingService: ShoppingService, private fb: FormBuilder) {
    this.addToCartForm = this.fb.group({
      add_to_cart_rows: new FormArray([])
    });
  }

  ngOnInit() {
    this.pricingService.getPricingForProduct(
      this.product_id,
      this.shoppingService.gateway,
      this.shoppingService.currency,
      this.shoppingService.membership).subscribe(
        success => {
          this.product = success;
          this.addFormControls();
        }
    );
  }

  canAddItem(i: ProductItem): boolean {
    if (!i.format_code) {
      return false;
    }
    return this.shoppingService.canAddItem(i);
  }

  addFormControls() {
    // For every item, add a corresponding formgroup
    this.product.items.forEach( (pi) => {
      this.addToCartForm.addControl(`quantity_${pi.id}`, new FormControl('1', [Validators.required, Validators.min(1), Validators.pattern(/^\d*$/)]));
      if (pi.format_code === 'PLUS' || (pi.downloadable && pi.multi_user_quantities.length < 2)) {
        this.addToCartForm.controls[`quantity_${pi.id}`].disable();
      }
      if (!this.canAddItem(pi)) {
        this.addToCartForm.controls[`quantity_${pi.id}`].disable();
      }
    });

  }
  shopping_currency() {
    return this.shoppingService.currency;
  }

  price_for_quantity(product_item: ProductItem, quantity: number) {
    return product_item.multi_user_prices.filter( (mp) => mp.quantity === quantity )[0].price;
  }

  submitted(v: number) {
    this._submitted = v;
  }

  addToDestination(): string {
    if (this.shoppingService.order === null) {
      return "Cart";
    } else {
      return "Order";
    }
  }

  onSubmit(e) {
    const pi = this.product.items.filter( (i) => i.id === this._submitted )[0];
    console.log('Adding: ',pi);
    console.log('Quantity: ',this.addToCartForm.controls[`quantity_${this._submitted}`].value);
    this.shoppingService.addItem(pi, this.addToCartForm.controls[`quantity_${this._submitted}`].value);
    this.dialogRef.close(true);
  }

  showUnpublishedWarning(): boolean {
    return !!this.product.items.filter(i => i.needs_sync).length;
  }

}
